import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { StaticTemplate } from '../../../templates/staticTemplate'
import { Layout } from '../../../components/layouts/layout'
import { PageMeta } from '../../../components/page-meta'
import { CalculatorSectionModule } from '../../../components/modules/CalculatorSectionModule'
import { PFNetWorthCalculator } from '../../../components/calculators'
import { anchorLinkSections, CrossSellTout } from './constants'
import { usePages } from '../constants'

const NetWorthPage = ({ pageContext, location }) => {
  const [t] = useTranslation()
  const { calculatorsPage, personalFinancePage } = usePages()

  const pageData = [
    {
      sectionTitle: t('NetWorthPage.subTitle', {
        defaultValue: "What's my net worth?",
      }),
      richBody: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('NetWorthPage.textContent', {
                  defaultValue:
                    "You can't put a price on happiness, but your net worth can help you better understand your financial well-being. Net worth takes into account your assets and liabilities to give you a sense of where you stand financially. Knowing your number allows you to see adjustments you might need to make to reach your goals.",
                }),
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
  ]

  return (
    <StaticTemplate pageContext={pageContext}>
      {({ navMenus }) => (
        <Layout
          location={location}
          pageContext={{ ...pageContext, pathname: personalFinancePage.urlPath }}
          menu={{ ...navMenus, subnav: [calculatorsPage] }}
          showSubnav
        >
          <PageMeta
            language={pageContext.langKey}
            title={t('NetWorthPage.pageTitle', {
              defaultValue: 'Net Worth Calculator',
            })}
            pathname="/calculators/personal-finance/net-worth"
          />
          <CalculatorSectionModule
            pageTitle={t('NetWorthPage.title', {
              defaultValue: 'Personal Finance Calculators',
            })}
            sections={pageData}
            calculator={<PFNetWorthCalculator />}
            anchorLinkSections={anchorLinkSections()}
          />
          <CrossSellTout />
        </Layout>
      )}
    </StaticTemplate>
  )
}

NetWorthPage.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export { NetWorthPage }
export default NetWorthPage
